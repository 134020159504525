import BottomNavbar from "@/components/BottomNavbar.tsx";
import { createAsync, useLocation, useNavigate, useSearchParams } from "@solidjs/router";
import { getOnboardingState } from "@/components/cached-queries.ts";
import {
  createEffect,
  createSignal,
  Match,
  onCleanup,
  onMount,
  Show,
  Switch,
} from "solid-js";
import { useConnectionEvent } from "@/components/connection.ts";
import {
  playerStore,
  playerStoreSelector,
  refreshPlayerState,
  refreshPlayerStatusEffects,
  setPlayerStore,
} from "@/components/player-store.ts";
import {
  captureEvent,
  clicksAnalyticStore,
  identifyUser,
  resetClicksAnalyticsStore,
} from "@/lib/analytics";
import { useGame } from "@/components/GameProvider.tsx";
import { HUD } from "@/components/HUD.tsx";
import { CoinsAwayModal, createCoinsChangeQuery } from "./CoinsAwayModal";
import { DailyStreakModal } from "./DailyStreakModal";
import { eventBus } from "./event-bus";
import { playSfxSound } from "./sounds";
import {
  addErrorToClicksStore,
  clicksStore,
  resetClicksStore,
  resetErrorCountClicksStore,
} from "@/lib/feed-mine-clicks";
import { ClicksService, ConnectSocialsService, GameService, RaffleTicketsService } from "@/api-client";
import {
  initializeEnergyBall,
  isEnergyBallEnabled,
} from "@/components/energy-ball.ts";
import { WelcomeMessages } from "@/components/WelcomeMessages.tsx";
import { BonusTicketModal } from "./BonusTicketModal";
import posthog from "posthog-js";
import ClickTracker from "./ClickTracker";
import { BitgetModal } from "./BitgetModal";
import DialogBoostersOverlay, {
  ftueBoosterStoreSelectors,
} from "./DialogBoostersOverlay";
import BackgroundOverlay from "./BackgroundOverlay";
import { createQuery, useQueryClient } from "@tanstack/solid-query";
import StatusEffectLayout from "./StatusEffectLayout";
import { showToast } from "./ui/toast";
import refreshLastVisitDateTime from "./lib/refresh-last-visit-datetime";
import { AFKReloadModal } from "./AFKReloadModal";
import { WildsConnectModal } from "./WildsConnectModal";

export function GameLayout(props: { children?: any }) {
  identifyUser();
  refreshPlayerState().then((r) => console.log("Player state initialized"));

  const location = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const game = useGame();
  const onboardingState = createAsync(() => getOnboardingState());
  const [isInitialized, setIsInitialized] = createSignal(false);
  const [isCoinsModalOpen, setIsCoinsModalOpen] = createSignal(true);
  const coinsChangeQuery = createCoinsChangeQuery();

  const [isDailyStreakModalOpen, setIsDailyStreakModalOpen] =
    createSignal(false);

  const openDailyStreakModal = () => {
    captureEvent("open_daily_streak_modal");
    setIsDailyStreakModalOpen(true);
  };
  const closeDailyStreakModal = () => setIsDailyStreakModalOpen(false);
  const afkShouldRefresh = refreshLastVisitDateTime(false);
  const [errorShouldRefresh, setErrorShouldRefresh] = createSignal(false);

  // Every 10 seconds, send a posthog event with player clicks
  // let clicksAnalyticsInternal: NodeJS.Timeout;
  // createEffect(() => {
  //   if (playerStore.beast && !clicksAnalyticsInternal) {
  //     clicksAnalyticsInternal = setInterval(() => {
  //       if (
  //         clicksAnalyticStore.feedClicksAnalytics > 0 ||
  //         clicksAnalyticStore.mineClicksAnalytics > 0
  //       ) {
  //         captureEvent("clicks", {
  //           feedClicks: clicksAnalyticStore.feedClicksAnalytics,
  //           mineClicks: clicksAnalyticStore.mineClicksAnalytics,
  //         });
  //         resetClicksAnalyticsStore();
  //       }
  //     }, 10000);
  //   }
  // });

  // Every 1 second send clicks to server. Feed and Mine
  let clicksInternal: NodeJS.Timeout | null;
  createEffect(() => {
    if (playerStore.beast && !clicksInternal) {
      clicksInternal = setInterval(() => {
        if (clicksStore.mineClicks > 0 || clicksStore.feedClicks > 0) {
          ClicksService.miningAndFeeding({
            requestBody: {
              feedAmount: clicksStore.feedClicks,
              mineAmount: clicksStore.mineClicks,
            },
          })
            .then(() => {
              console.log("SEND MINE AND FEED CLICKS");
            })
            .catch(async (err) => {
              if (clicksStore.errorCount > 3) {
                resetErrorCountClicksStore();
                setErrorShouldRefresh(true);
              }
              showToast({
                title: "Error sending clicks",
                description: "Reloading state",
              });
              addErrorToClicksStore();
              console.error("ERROR MINING AND FEEDING", err);
              await refreshPlayerState();
              await refreshPlayerStatusEffects();
            });
          resetClicksStore();
        }
      }, 1000);
      onCleanup(() => {
        if (clicksInternal) {
          clearInterval(clicksInternal);
          clicksInternal = null;
        }
      });
    }
  });

  // At start send a posthog event with coins, coinrate, happiness
  // let firstEvent = false;
  // createEffect(() => {
  //   if (playerStore.beast && firstEvent === false) {
  //     if (!firstEvent) {
  //       firstEvent = true;
  //     }
  //     captureEvent("gameState", {
  //       coins: playerStoreSelector.coins(),
  //       coinsEarningRate: playerStoreSelector.coinsEarningRate(),
  //       happiness: playerStoreSelector.beastHappiness(),
  //       beastLevel: playerStore.beast.level,
  //     });
  //   }
  // });

  createEffect(() => {
    if (playerStore.beast) {
      let interval = setInterval(() => {
        GameService.updateCoinsSnapshot().then((data: any) => {
          setPlayerStore("coinsSnapshot", data);
        });
      }, 5000);

      onCleanup(() => {
        clearInterval(interval);
      });
    }
  });

  createEffect(function initializeGame() {
    if (!isInitialized() && playerStore.beast && game()) {
      game()!
        .initialize(playerStore.beast.specieStageId)
        .then(async () => {
          if (isEnergyBallEnabled()) {
            console.log("GO");
            await initializeEnergyBall(game()!).catch(() => {
              console.error("Failed to initialize energy ball");
            });
          }
          setIsInitialized(true);

          // Get Raffle Tickets since first snapshot
          const raffleSnapshotTicketsData =
            await RaffleTicketsService.getTicketsSinceFirstSnapshot();
          queryClient.setQueryData(
            ["raffleSnapshotTickets"],
            raffleSnapshotTicketsData,
          );
        })
        .catch((err) => {
          console.log("FAILED TO INITIALIZE GAME");
        });
    }
  });

  useConnectionEvent("MineExtraLuckyShard", () => {
    eventBus.emit("MineLuckyShard");
    playSfxSound("luckyShardDrop");
  });

  useConnectionEvent("BonusTicketEarned", () => {
    eventBus.emit("levelUpRaffleTicket");
    queryClient.invalidateQueries({
      queryKey: ["raffleTickets"],
    });
  });

  createEffect(async () => {
    if (!onboardingState()?.isCompleted) {
      navigate("/onboarding");
    }
  });

  useConnectionEvent("ForceRefreshPlayerState", async () => {
    await refreshPlayerState();
  });

  const [isBonusTicketModalOpen, setIsBonusTicketModalOpen] =
    createSignal(false);
  const [isBidgetModalOpen, setIsBidgetModalOpen] = createSignal(false);
  const [wildsConnected, setWildsConnected] = createSignal(false);
  const [isWildsConnectModalOpen, setIsWildsConnectModalOpen] =
  createSignal<boolean | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  // TODO: add a specific query for zephyrus aka wilds hub
  const connectedSocialsQuery = createQuery(() => ({
    queryKey: ["GameLayout/getConnectedSocials"],
    queryFn: () => ConnectSocialsService.getConnectedSocials(),
  }));

  createEffect(() => {
    if (connectedSocialsQuery.data !== undefined && isWildsConnectModalOpen() === null)
      {
        setWildsConnected(connectedSocialsQuery.data?.zephyrus ?? false)
        setIsWildsConnectModalOpen(!wildsConnected())
      }
  }, [connectedSocialsQuery.data]);

  createEffect(() => {
    if (searchParams.openWildsConnect === "true")
      {
        setIsWildsConnectModalOpen(true);
        setSearchParams({ openWildsConnect: undefined });
      }
  }, [searchParams])

  return (
    <Show when={onboardingState()?.isCompleted && isInitialized()}>
      <Show when={afkShouldRefresh() || errorShouldRefresh()}>
        <AFKReloadModal
          closeModal={() => null}
          title={errorShouldRefresh() ? "Error" : undefined}
        />
      </Show>
      <Show when={location.pathname === "/play"}>
        <Switch fallback={<WelcomeMessages />}>
          <Match when={isCoinsModalOpen() && coinsChangeQuery.data! > 5}>
            <CoinsAwayModal
              closeModal={() => setIsCoinsModalOpen(false)}
              coinsChange={coinsChangeQuery.data!}
            />
          </Match>
          <Match when={isDailyStreakModalOpen()}>
            <DailyStreakModal
              closeModal={closeDailyStreakModal}
              isModalOpen={isDailyStreakModalOpen}
              setIsModalOpen={setIsDailyStreakModalOpen}
            />
          </Match>
          <Match
            when={
              isBonusTicketModalOpen() &&
              posthog.isFeatureEnabled("first_raffle_special_signup")
            }
          >
            <BonusTicketModal
              closeModal={() => {
                setIsBonusTicketModalOpen(false);
              }}
              isModalOpen={isBonusTicketModalOpen}
              setIsModalOpen={setIsBonusTicketModalOpen}
              title="Get Bonus Tickets!"
            />
          </Match>
          <Match
            when={isBidgetModalOpen() && posthog.isFeatureEnabled("bitget")}
          >
            <BitgetModal
              closeModal={() => {
                setIsBidgetModalOpen(false);
              }}
              isModalOpen={isBidgetModalOpen}
              setIsModalOpen={setIsBidgetModalOpen}
            />
          </Match>
          <Match when={isWildsConnectModalOpen()}>
            <WildsConnectModal
              closeModal={() => {
                setIsWildsConnectModalOpen(false);
              }}
              wildsConnected={wildsConnected()}
              isModalOpen={isWildsConnectModalOpen}
              setIsModalOpen={setIsWildsConnectModalOpen}
            />
          </Match>
          <Match
            when={
              playerStore &&
              playerStoreSelector.hasEffect("UnlimitedEnergyEffect")
            }
          >
            <StatusEffectLayout />
          </Match>
        </Switch>
      </Show>
      <Show when={location.pathname != "/play/match"}>
        <HUD
          openDailyStreakModal={openDailyStreakModal}
          // openLuckySerumSaleModal={openLuckySerumSaleModal}
          openBonusTicketModal={() => setIsBonusTicketModalOpen(true)}
          openBidgetModal={() => setIsBidgetModalOpen(true)}
          openWildsConnectModal={() => setIsWildsConnectModalOpen(true)}
        />
      </Show>
      <Show when={location.pathname != "/play"}>
        <div class="drawerOverlay"></div>
        <div>{props.children}</div>
      </Show>
      <Show when={location.pathname != "/play/match"}>
        <BottomNavbar />
      </Show>
      <Show when={ftueBoosterStoreSelectors.initialized()}>
        <DialogBoostersOverlay />
      </Show>
      <ClickTracker />
    </Show>
  );
}
